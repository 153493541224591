<template>
<div>
    <div class="tblup">
        <div class="glotbl spcwrp">
            <div class="tbTit">
                联系信息
            </div>
            <div class="tbInnr">
                <label for="">姓名</label>
                <input type="text" placeholder="请填写学员姓名" v-model="name">
                <span class="span_dian" @click="showCandain('name')">...</span>
            </div>
            <!-- <div class="tbInnr">
                <label for="">学员身份证</label>
                <input type="text" placeholder="请填写学员身份证号码" v-model="certno">
                <span class="span_dian" @click="showCandain('certno')">...</span>
            </div>
            <div class="tbInnr">
                <label>所在年级</label>
                <input type="text" placeholder="请填写学员年级段" v-model="name_grade">
            </div>
            <div class="tbInnr">
                <label>家长姓名</label>
                <input type="text" placeholder="请填写学员家长姓名" v-model="name_parent">
            </div>
            <div class="tbInnr">
                <label>单位/组织</label>
                <input type="text" placeholder="所在防疫志愿队或单位" v-model="name_company">
            </div> -->
            <!-- 学员姓名，年级，家长姓名、参与疫情防控的志愿服务组织或者所在单位 -->
            <div class="tbInnr">
                <label for="">手机</label>
                <input type="text" placeholder="请填写家长手机号码" v-model="phone">
                <span class="span_dian" @click="showCandain('phone')">...</span>
            </div>

        </div>
        <div class="glotbl">
            <div class="tbTit">
                地址
            </div>
            <div class="tbInnr specilWrp">
                <label style="margin-right: 1rem;">区域</label>
                <SelectCity @setLocal="getLocalData" />
            </div>
            <div class="tbInnr">
                <label for="">详细地址</label>
                <input style="padding-left: 1rem;" type="text" placeholder="请填写居住地或详细地址" v-model="address">
                <span class="span_dian" @click="showCandain('address')">...</span>
            </div>
        </div> 
        <!-- 		<div class="swichwrp">

<text>是否设为默认地址</text>

<switch color="#F97A0A" checked="checked"></switch>

</div> -->
    </div>
    <div class="tblup">
        <div class="glotbl">
            <div class="totalWrp">
                <img :src="item.image?domin+item.image.Images_Url:'../assets/standImg.jpg'" />
                <div class="tbTits">
                    <div class="tb-mintit">{{item.name}}</div>
                    <div class="tb-mod"> {{item.stock.Stock_Color}} {{item.stock.Stock_Size}}
                        {{item.stock.Stock_Variety}}
                    </div>
                </div>
                <label>x {{item.count}}</label>
                <label>¥{{item.sellPrice}}</label>
            </div>
            <div class="tbInnr tbCount">
                <label>配送费</label>
                <label>¥{{item.Freight}}</label>
            </div>
        </div>
        <!-- 		<div class="glotbl">

<div class="tbInnr tbCount">

<text>店铺优惠</text>

<text class="ans">无可使用红包</text>

</div>

</div> -->
        <div class="glotbl">
            <div class="tbInnr tbCount">
                <div></div>
                <div>
                    <text class="ans">合计</text>
                    <text>¥{{total}}</text>
                </div>

            </div>
        </div>
    </div>
    <div class="Btmwrp">
        <label>¥{{total}}</label>
        <a herf="javascript:void(0);" @click="sendOrder">去支付</a>
    </div>
    <Candia v-show="show" :mdata="current_Arr" @closeDain="closeCandia"  @setVal="setCandia"/>
    <Recommd v-if="recommdList.length > 0" :list="recommdList"></Recommd>

</div>
</template>

<script>
import SelectCity from "@/components/SelectCity"
import Candia from "@/components/Candia"
import Recommd from "@/components/Recommd"

export default {
    data() {
        return {
            domin: this.domin,
            ordersid: 0,
            name: "",
            certno:"",
            name_parent: "",
            name_company: "",
            name_grade: "",
            phone: "",
            item: {},
            mid: 0,
            address: "",
            detailsAdress: "",
            price: 0,
            total: 0,
            Merchant_Name: "",
            Merchant_DeliveryFee: 0,
            order_array: [],
            orderModel: "",
            adrsvalue: ['省份', '城市', '区县'],
            cityPickerValueDefault: [10, 2, 0],
            pickerText: '',
            district: "",
            ordersid: 0,
            addressArr: [],
            recentInData: [],
            address_Arr: [],
            name_Arr: [],
            phone_Arr: [],
            certno_Arr:[],
            current_Arr: [],
            current_Type: "",
            recommdList:[],
            show: false
        }
    },
    mounted() {
        this.getRecentInData()
    },
    components: {
        SelectCity,
        Candia,
        Recommd

    },
    created(){
        console.log("item:",JSON.parse(localStorage.getItem("itemBuy")));


        this.item =  JSON.parse(localStorage.getItem("itemBuy"))
        this.price = this.item.count * this.item.sellPrice
        this.total = this.price + this.item.Freight

    },
    methods: {
        showCandain(type) {
            this.show = true;
            this.current_Arr = this[type + "_Arr"]
            this.current_Type = type
            console.log("current_Arr", this.current_Arr)
        },
        closeCandia() {
            this.show = false;
        },
        setCandia(val) {
            this[this.current_Type] = val
            console.log("val", val)
        },
        getLocalData(data) {
            console.log("local name", data)
            this.district = data
        },
        candidates(typ) {
            if (typ == 'name') {
                return this.recentInData.map(item => {
                    if (item.Orders_Receiver.split("_").length > 1) {
                        return item.Orders_Receiver.split("_")[0]
                    } else {
                        return item.Orders_Receiver
                    }
                })
            } else if (typ == 'address') {
                return this.recentInData.map(item => {
                    return item.Orders_Address

                })
            } else if (typ == 'phone') {
                return this.recentInData.map(item => {
                    return item.Orders_Phone

                })
            }
             else if (typ == 'certno') {
                return this.recentInData.map(item => {
                    if(!!item.Orders_CertNo){
                        return item.Orders_CertNo
                    }
                
                })
            }
        },
        getRecentInData() {
            this.wpost({
                url: '/Ajax/WordWelfare/getorderslist',
                succ: res => {
                    console.log("getRecentInData", res)
                    this.recentInData = res.list

                    this.name_Arr = Array.from(new Set(this.candidates('name').filter(item => !this.isNil(item))))
                    this.phone_Arr = Array.from(new Set(this.candidates('phone').filter(item => !this.isNil(item))))
                    this.address_Arr = Array.from(new Set(this.candidates('address').filter(item => !this.isNil(item))))
                    this.certno_Arr = Array.from(new Set(this.candidates('certno').filter(item => !this.isNil(item))))

                }
            })
        },
        sendOrder() {
            if (this.name == '') {
                alert('请填写收件人姓名')

                return false
            }
            if (this.phone == '') {
                alert('请填写收件人的手机号码')
                return false
            }
            if (this.address == '') {
                alert('请填写收件地址')
                return false
            }

            if (this.phone == '' || this.phone.length != 11) {
                alert('请填写收件人手机号码')
                return false
            }
            if (this.district == '') {
                alert('请选择收件人的省市区')
                return false
            }


            this.wpost({
                url: '/Ajax/WordWelfare/createordersdirectbuymaterial',
                data: {
                    stockid: this.item.stockid,
                    count: this.item.count,
                    certno:this.certno,
                    name: this.name,
                    phone: this.phone,
                    address: this.district + this.address
                },
                fail:(res) =>{
                    console.log("进入了fail", res.data.productlist)
                    this.recommdList = res.data.productlist
                },
                succ: (data) => {
                    console.log("订单:", data)
                    this.ordersid = data.ordersid

                    if (data.ordersstatus == 2) {
                        alert('已成功下订单')
                        this.$router.push('/orderDetails/' + this.ordersid+'/1')

                        return
                    }

                    // 发起支付

                    this.wpost({
                        url: '/Ajax/WordWelfare/getwxpaydata',
                        data: {
                            ordersid: this.ordersid,
                            contactname: [this.name, this.name_grade, this.name_grade, this.name_company].join("_"),
                            contactphone: this.phone,
                            contactprovince: this.addressArr[0],
                            contactcity: this.addressArr[1],
                            contactarea: this.addressArr[2],
                            contactaddress: this.address
                        },
                        succ: (data) => {

                            WeixinJSBridge.invoke(
                                'getBrandWCPayRequest', {
                                    "appId": data.wxdata.appId, //公众号名称，由商户传入     
                                    "timeStamp": data.wxdata
                                        .timeStamp, //时间戳，自1970年以来的秒数     
                                    "nonceStr": data.wxdata.nonceStr, //随机串     
                                    "package": data.wxdata.package,
                                    "signType": data.wxdata.signType, //微信签名方式：     
                                    "paySign": data.wxdata.paySign //微信签名 
                                },
                                (res) => {
                                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                                        alert('支付成功!')
                                        this.$router.push('/orderDetails/' + this.ordersid)
                                    } else {
                                        alert('支付失败!')
                                    }
                                });

                        }
                    })

                }
            })

        },
        
    }
}
</script>

<style>
page {
    padding-bottom: 70px;
}

.glotbl {
    background-color: #fff;
}

.totalWrp {
    padding: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.totalWrp img {
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.swichwrp switch {
    scale: 0.8;
}

.swichwrp {
    padding: 5px 0;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tblup {
    margin: 12px;
    border-radius: 6px;
    box-shadow: 0 0 6px #ccc;
    overflow: hidden;
}

.tbTit,
.tbInnr {
    padding: 0 12px;
    box-sizing: border-box;
}

.tbInnr label {
    font-size: 17px;
}

.tbTit {
    position: relative;
    padding-left: 17px;
    color: #fff;
    font-size: 14px;
    line-height: 2;
    background-color: #f97a0a;
}

.tbTit::before {
    content: "";
    display: block;
    height: 18px;
    width: 3px;
    position: absolute;
    left: 6px;
    top: 5px;
    background-color: #fff;
}

.tbInnr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 3.1;
    border-bottom: 1px solid #e9e9e9;
}

.tbInnr label {
    font-size: 14px;
    width: 80px;
}

.tbInnr input {
    border: none;
    outline: none;
    width: 76%;
    font-size: 16px;
    line-height: 2.1;
    box-sizing: border-box;
}

picker {
    width: 100%;
    font-size: 15px;
    padding-left: 1rem;
    height: 43px;
    box-sizing: border-box;
}

picker .uni-input {
    height: 43px;
    line-height: 43px;
}

.uni-input {
    height: 25px;
    line-height: 25px;
}

.tbInnr textarea {
    width: 100%;
    margin: 8px 0;
    border-radius: 5px;
    padding: 6px;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.7;
}

.adrsWrp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #333;
}

.adrsWrp text {
    width: 31%;
}

.tb-mod {
    white-space: normal;
    font-size: 12px;
    margin-top: 5px;
}

.tbTits {
    white-space: nowrap;
    width: 50%;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tb-mintit{
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tbCount,
.tbTits {
    color: #555;
    font-size: 13px;
    font-weight: bold;
}

.tbCount {
    justify-content: space-between;

}

.tbCount .ans {
    color: #a0a0a0;
    font-size: 13px;
}

.tbftp {
    align-items: flex-start;
}

.Btmwrp {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 89;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #333;

}

.Btmwrp label {
    margin-left: 12px;
    font-size: 30px;
}

.Btmwrp a {
    color: #fff;
    display: inline-block;
    background-color: #F97A0A;
    margin: 0;
    height: 47px;
    line-height: 47px;
    padding: 0 22px;
    border-radius: 22px;
    text-align: center;
    font-size: 15px;
}
</style>
